/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata, __param } from 'tslib';
import { Injectable, Input, Directive, ElementRef, Renderer2, TemplateRef, Optional, EventEmitter, NgZone, HostBinding, ContentChild, ViewChild, ViewChildren, QueryList, Output, Component, ChangeDetectionStrategy, ViewContainerRef, HostListener, NgModule } from '@angular/core';
import { isDocumentAvailable, Keys, isChanged, hasObservers, EventsModule } from '@progress/kendo-angular-common';
import { validatePackage } from '@progress/kendo-licensing';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { Subject, Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { switchMap, take } from 'rxjs/operators';
import { PageSizeChangeEvent, PagerModule } from '@progress/kendo-angular-pager';
import { CommonModule } from '@angular/common';
import { Button } from '@progress/kendo-angular-buttons';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-listview',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1642583009,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

const LISTVIEW_ITEM_SELECTOR = '.k-listview-item';
/**
 * @hidden
 */
const isPresent = (item) => item !== null && item !== undefined;
/**
 * @hidden
 *
 * Polyfill for `Element.matches`.
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
 */
const match = (element, selector) => {
    const matcher = element.matches || element.msMatchesSelector || element.webkitMatchesSelector;
    if (!isPresent(matcher)) {
        return false;
    }
    return matcher.call(element, selector);
};
/**
 * @hidden
 *
 * Checks if a target element has the `.k-listview-item` CSS class.
 */
const isListViewItem = (element) => {
    if (!isPresent(element)) {
        return false;
    }
    return match(element, LISTVIEW_ITEM_SELECTOR);
};
/**
 * @hidden
 *
 * Extracts and parses to a number the `data-kendo-listview-item-index` attribute value from the targeted element.
 */
const getListItemIndex = (item) => {
    if (!isPresent(item)) {
        return null;
    }
    return Number(item.getAttribute('data-kendo-listview-item-index'));
};
/**
 * @hidden
 *
 * Gets the new focus target from a blur event.
 * Queries both event.relatedTarget and document.activeElement for compatibility with IE.
 */
const relatedTarget = (event) => {
    if (!isPresent(event.relatedTarget) || !isDocumentAvailable()) {
        return null;
    }
    return event.relatedTarget || document.activeElement;
};
/**
 * @hidden
 *
 * If the given contender number is not defined or lower than the specified min - returns min, if its above the specified max - returns max.
 * If the number is in the given bounds, it is returned.
 */
const fitIntoRange = (contender, min, max) => {
    if (!isPresent(contender) || contender <= min) {
        return min;
    }
    else if (contender >= max) {
        return max;
    }
    else {
        return contender;
    }
};
/**
 * @hidden
 */
const closestWithMatch = (element, selector) => {
    let parent = element;
    while (parent !== null && parent.nodeType === 1) {
        if (match(parent, selector)) {
            return parent;
        }
        parent = parent.parentElement || parent.parentNode;
    }
    return null;
};
/**
 * @hidden
 *
 * Extracts and parses to a number the `data-kendo-listview-item-index` attribute value from the targeted element.
 */
const getClosestListItemIndex = (element) => {
    if (!isPresent(element)) {
        return null;
    }
    const closestListViewItem = closestWithMatch(element, LISTVIEW_ITEM_SELECTOR);
    return getListItemIndex(closestListViewItem);
};

/**
 * @hidden
 *
 * Provided per ListView instance. Keeps the availability, active index and focused state of the current ListView.
 * Emits `changes` when any of the aforementioned states changes.
 */
let NavigationService = class NavigationService {
    /**
     * @hidden
     *
     * Provided per ListView instance. Keeps the availability, active index and focused state of the current ListView.
     * Emits `changes` when any of the aforementioned states changes.
     */
    constructor() {
        /**
         * Emits every time a change in active index/focus/blur/navigation availability occurs.
         */
        this.changes = new Subject();
        /**
         * Specifies if a ListView item currently holds focus.
         */
        this.isFocused = false;
        /**
         * Keeps track of the index of the items that should be the current focus target (tabindex="0").
         * When set to `null`/`undefined`, the navigation is disabled and the items should not render a tabindex.
         */
        this.activeIndex = null;
    }
    /**
     * Sets or gets if the navigation is enabled.
     * When no activeIndex is present, the navigation is inferred as disabled.
     * Toggling the service availability clears the current active index or activates the first one.
     */
    get isEnabled() {
        return isPresent(this.activeIndex);
    }
    set isEnabled(enabled) {
        if (enabled) {
            this.activeIndex = 0;
        }
        else {
            this.activeIndex = null;
        }
        this.changes.next();
    }
    /**
     * Shows if the checked index should be the current available focus target (tabindex="0").
     */
    isActive(index) {
        return index === this.activeIndex;
    }
    handleKeyDown(event, totalItemsCount) {
        const { keyCode } = event;
        switch (keyCode) {
            case Keys.ArrowLeft:
            case Keys.ArrowUp:
                this.navigateToPrevious();
                break;
            case Keys.ArrowRight:
            case Keys.ArrowDown:
                this.navigateToNext(totalItemsCount);
                break;
            case Keys.Home:
                const firstIndex = 0;
                this.navigateTo(firstIndex);
                break;
            case Keys.End:
                const lastIndex = totalItemsCount - 1;
                this.navigateTo(lastIndex);
                break;
            default: return;
        }
        // the following line is executed only if the pressed key matches one of the listview hotkeys -
        // they `break`, while the `default` case `return`s
        event.preventDefault();
    }
    handleFocusIn(event) {
        const target = event.target;
        if (!isListViewItem(target)) {
            const listViewItemSelector = '.k-listview-item';
            const closestListViewItem = closestWithMatch(target, listViewItemSelector);
            const isListViewItemChild = isPresent(closestListViewItem);
            if (isListViewItemChild) {
                const itemIndex = getListItemIndex(closestListViewItem);
                this.setActiveIndex(itemIndex);
            }
            return;
        }
        const targetIndex = getListItemIndex(target);
        // don't emit if the no change in focused state has occurred and the targeted index is the same as the current activeIndex
        if (this.isFocused && targetIndex === this.activeIndex) {
            return;
        }
        this.activeIndex = targetIndex;
        this.isFocused = true;
        this.changes.next();
    }
    handleFocusOut(event) {
        // don't emit if the blurred item is not a listview item or if the new focus target is another listview item
        if (!isListViewItem(event.target) || isListViewItem(relatedTarget(event))) {
            return;
        }
        this.isFocused = false;
        this.changes.next();
    }
    /**
     * Sets the `activeIndex` and triggers changes without focusing the corresponding ListView item.
     */
    setActiveIndex(index) {
        if (!this.isEnabled) {
            return;
        }
        if (index === this.activeIndex) {
            return;
        }
        this.activeIndex = index;
        this.changes.next();
    }
    /**
     * Focuses item at the targeted index. If no index is passed, the current `activeIndex` is used.
     * The passed target index is normalized to fit the min/max available indices bounds.
     */
    focusIndex(index, totalItemsCount) {
        if (!this.isEnabled) {
            return;
        }
        const parsed = parseInt(index, 10);
        const firstIndex = 0;
        const lastIndex = totalItemsCount - 1;
        const targetIndex = isNaN(parsed) ? this.activeIndex : fitIntoRange(parsed, firstIndex, lastIndex);
        this.navigateTo(targetIndex);
    }
    navigateTo(index) {
        if (this.isFocused && this.activeIndex === index) {
            return;
        }
        this.isFocused = true;
        this.activeIndex = index;
        this.changes.next();
    }
    navigateToPrevious() {
        const previousIndex = Math.max(this.activeIndex - 1, 0);
        this.navigateTo(previousIndex);
    }
    navigateToNext(totalItemsCount) {
        const lastAvailableIndex = totalItemsCount - 1;
        const nextIndex = Math.min(this.activeIndex + 1, lastAvailableIndex);
        this.navigateTo(nextIndex);
    }
};
NavigationService = __decorate([
    Injectable()
], NavigationService);

/**
 * @hidden
 */
let ListViewNavigableItemDirective = class ListViewNavigableItemDirective {
    constructor(hostElement, renderer, navigationService) {
        this.hostElement = hostElement;
        this.renderer = renderer;
        this.navigationService = navigationService;
    }
    ngOnChanges() {
        this.updateNavigationState();
    }
    ngOnInit() {
        this.navigationSubscription = this.navigationService.changes
            .subscribe(this.updateNavigationState.bind(this));
    }
    ngOnDestroy() {
        if (isPresent(this.navigationSubscription)) {
            this.navigationSubscription.unsubscribe();
        }
    }
    updateNavigationState() {
        this.updateTabIndex();
        this.updateFocusedState();
    }
    updateFocusedState() {
        const shouldFocus = this.navigationService.isActive(this.index) && this.navigationService.isFocused;
        const focusedCssClass = 'k-state-focused';
        if (shouldFocus) {
            this.renderer.addClass(this.hostElement.nativeElement, focusedCssClass);
            this.hostElement.nativeElement.focus();
        }
        else {
            this.renderer.removeClass(this.hostElement.nativeElement, focusedCssClass);
        }
    }
    updateTabIndex() {
        if (!this.navigationService.isEnabled) {
            this.renderer.removeAttribute(this.hostElement.nativeElement, 'tabindex');
        }
        else if (this.navigationService.isActive(this.index)) {
            this.renderer.setAttribute(this.hostElement.nativeElement, 'tabindex', '0');
        }
        else {
            this.renderer.setAttribute(this.hostElement.nativeElement, 'tabindex', '-1');
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Number)
], ListViewNavigableItemDirective.prototype, "index", void 0);
ListViewNavigableItemDirective = __decorate([
    Directive({
        selector: '[kendoListViewNavigableItem]'
    }),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2,
        NavigationService])
], ListViewNavigableItemDirective);

/**
 * Renders the list item content. To define an item template, nest an `<ng-template>` tag
 * with the `kendoListViewItemTemplate` directive inside the `<kendo-listview>` tag
 * [see example]({% slug templates_listview %}#toc-item-template).
 *
 * The following values are available as context variables:
 * - `let-dataItem="dataItem"` (`any`) - The current data item. Also available as implicit context variable.
 * - `let-index="index"` (`number`) - The current item index.
 * - `let-isFirst="isFirst"` (`boolean`) - Indicates whether the current data item will be rendered as the first item on the list.
 * - `let-isLast="isLast"` (`boolean`)- Indicates whether the current data item will be rendered as the last item on the list.
 */
let ItemTemplateDirective = class ItemTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
ItemTemplateDirective = __decorate([
    Directive({
        selector: '[kendoListViewItemTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], ItemTemplateDirective);

/**
 * Renders the header content of the ListView. To define a header template, nest an `<ng-template>` tag
 * with the `kendoListViewHeaderTemplate` directive inside the `<kendo-listview>` tag
 * [see example]({% slug templates_listview %}#toc-header-template).
 */
let HeaderTemplateDirective = class HeaderTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
HeaderTemplateDirective = __decorate([
    Directive({
        selector: '[kendoListViewHeaderTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], HeaderTemplateDirective);

/**
 * Renders the footer content of the ListView. To define a footer template, nest an `<ng-template>` tag
 * with the `kendoListViewFooterTemplate` directive inside the `<kendo-listview>` tag
 * [see example]({% slug templates_listview %}#toc-footer-template).
 */
let FooterTemplateDirective = class FooterTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
FooterTemplateDirective = __decorate([
    Directive({
        selector: '[kendoListViewFooterTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], FooterTemplateDirective);

/**
 * Overrides the default loader content of the ListView. To define a loader template, nest an `<ng-template>` tag
 * with the `kendoListViewLoaderTemplate` directive inside the `<kendo-listview>` tag
 * [see example]({% slug templates_listview %}#toc-loader-template).
 */
let LoaderTemplateDirective = class LoaderTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
LoaderTemplateDirective = __decorate([
    Directive({
        selector: '[kendoListViewLoaderTemplate]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], LoaderTemplateDirective);

/**
 * Represents the edit template of the ListView ([see example]({% slug editing_template_forms_listview %})).
 * Helps to customize the content of the edited items. To define the template, nest an `<ng-template>`
 * tag with the `kendoListViewEditTemplate` directive inside a `<kendo-listview>` tag.
 *
 * The template context contains the following fields:
 * - `formGroup`&mdash;The current [`FormGroup`]({{ site.data.urls.angular['formgroupapi'] }}).
 * If you use the ListView inside [Template-Driven Forms]({{ site.data.urls.angular['forms'] }}), it will be `undefined`.
 * - `itemIndex`&mdash;The current item index. If inside a new item, `itemIndex` is `-1`.
 * - `dataItem`&mdash;The current data item.
 * - `isNew`&mdash;The state of the current item.
 */
let EditTemplateDirective = class EditTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
EditTemplateDirective = __decorate([
    Directive({
        selector: '[kendoListViewEditTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], EditTemplateDirective);

/**
 * @hidden
 */
const isEqual = (index) => (item) => item.index === index;
/**
 * @hidden
 */
const isNotEqual = (index) => (item) => item.index !== index;
/**
 * @hidden
 */
const isNewItem = (index) => index === -1 || index === undefined;
/**
 * @hidden
 */
let EditService = class EditService {
    constructor(ngZone) {
        this.ngZone = ngZone;
        this.changes = new EventEmitter();
        this.editedIndices = [];
        this.changedSource = new Subject();
        this.changed = this.changedSource.asObservable().pipe(switchMap(() => this.ngZone.onStable.asObservable().pipe(take(1))));
    }
    editItem(index, group = undefined) {
        this.editedIndices.push({ index, group });
        this.onChanged();
    }
    addItem(group) {
        this.newItem = { group };
        this.onChanged();
    }
    isEditing() {
        return this.editedIndices.length > 0;
    }
    get hasNewItem() {
        return isPresent(this.newItem);
    }
    get newDataItem() {
        if (this.hasNewItem) {
            return this.newItem.group.value;
        }
        return {};
    }
    get newItemGroup() {
        if (this.hasNewItem) {
            return this.newItem.group;
        }
        return new FormGroup({});
    }
    editGroup(index) {
        return this.context(index).group;
    }
    close(index) {
        if (isNewItem(index)) {
            this.newItem = undefined;
            return;
        }
        this.editedIndices = this.editedIndices.filter(isNotEqual(index));
        this.onChanged();
    }
    context(index) {
        if (isNewItem(index)) {
            return this.newItem;
        }
        return this.findByIndex(index);
    }
    isEdited(index) {
        if (isNewItem(index) && isPresent(this.newItem)) {
            return true;
        }
        return isPresent(this.findByIndex(index));
    }
    hasEdited(index) {
        return isPresent(this.context(index));
    }
    beginEdit(itemIndex) {
        this.changes.emit({ action: 'edit', itemIndex });
    }
    beginAdd() {
        this.changes.emit({ action: 'add' });
    }
    endEdit(itemIndex) {
        const { group: formGroup } = this.context(itemIndex);
        this.changes.emit({ action: 'cancel', itemIndex, formGroup, isNew: isNewItem(itemIndex) });
    }
    save(itemIndex) {
        const { group: formGroup } = this.context(itemIndex);
        this.changes.emit({ action: 'save', itemIndex, formGroup, isNew: isNewItem(itemIndex) });
    }
    remove(itemIndex) {
        this.changes.emit({ action: 'remove', itemIndex });
    }
    findByIndex(index) {
        return this.editedIndices.find(isEqual(index));
    }
    onChanged() {
        this.ngZone.runOutsideAngular(() => {
            this.changedSource.next();
        });
    }
};
EditService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [NgZone])
], EditService);

const DEFAULT_PAGER_SETTINGS = {
    position: 'bottom',
    buttonCount: 5,
    info: true,
    previousNext: true,
    type: 'numeric',
    pageSizeValues: [5, 10, 20]
};
const createControl = (source) => (acc, key) => {
    acc[key] = new FormControl(source[key]);
    return acc;
};
/**
 * Represents the Kendo UI ListView component for Angular.
 */
let ListViewComponent = class ListViewComponent {
    constructor(ngZone, element, renderer, editService, navigationService) {
        this.ngZone = ngZone;
        this.element = element;
        this.renderer = renderer;
        this.editService = editService;
        this.navigationService = navigationService;
        /**
         * @hidden
         */
        this.className = true;
        /**
         * Specifies if the loading indicator of the ListView will be displayed
         * ([see example]({% slug paging_listview %}#toc-remote-binding)).
         */
        this.loading = false;
        /**
         * Specifies the content container `role` attribute
         * ([more details]({% slug accessibility_listview %}#toc-wai-aria-support)).
         * By default, the container `role` is set to `listbox`.
         */
        this.containerRole = 'listbox';
        /**
         * Specifies the list item `role` attribute
         * ([more details]({% slug accessibility_listview %}#toc-wai-aria-support)).
         * By default, the list item `role` is set to `option`.
         */
        this.listItemRole = 'option';
        /**
         * Fires when the user scrolls to the last record on the page
         * ([see endless scrolling example]({% slug scrollmodes_listview %}#toc-endless-scrolling)).
         */
        this.scrollBottom = new EventEmitter();
        /**
         * Fires when the page or the page size of the ListView is changed
         * ([see example]({% slug paging_listview %}#toc-remote-binding)).
         * You have to handle the event yourself and page the data.
         */
        this.pageChange = new EventEmitter();
        /**
         * Fires when the page size of the ListView is changed. This event can be prevented (`$event.preventDefault()`).
         * If not prevented, the `pageChange` event will be fired subsequently.
         */
        this.pageSizeChange = new EventEmitter();
        /**
         * Fires when the user clicks the **Edit** command button to edit an item
         * ([see example]({% slug editing_template_forms_listview %}#toc-editing-records)).
         */
        this.edit = new EventEmitter();
        /**
         * Fires when the user clicks the **Cancel** command button to close an item
         * ([see example]({% slug editing_template_forms_listview %}#toc-cancelling-editing)).
         */
        this.cancel = new EventEmitter();
        /**
         * Fires when the user clicks the **Save** command button to save changes in an item
         * ([see example]({% slug editing_template_forms_listview %}#toc-saving-records)).
         */
        this.save = new EventEmitter();
        /**
         * Fires when the user clicks the **Remove** command button to remove an item
         * ([see example]({% slug editing_template_forms_listview %}#toc-removing-records)).
         */
        this.remove = new EventEmitter();
        /**
         * Fires when the user clicks the **Add** command button to add a new item
         * ([see example]({% slug editing_template_forms_listview %}#toc-adding-records)).
         */
        this.add = new EventEmitter();
        this._skip = 0;
        this._navigable = false;
        validatePackage(packageMetadata);
        this.attachEditHandlers();
    }
    /**
     * Specifies whether the keyboard navigation is enabled
     * ([see example]({% slug keyboard_navigation_listview %})).
     * By default, the navigation is disabled.
     */
    set navigable(navigable) {
        if (!navigable && isPresent(this.removeNavigationListeners)) {
            this.removeNavigationListeners();
            this.removeNavigationListeners = null;
            this.navigationService.isEnabled = false;
        }
        else if (navigable && !isPresent(this.removeNavigationListeners)) {
            this.addNavigationListeners();
            this.navigationService.isEnabled = true;
        }
        this._navigable = navigable;
    }
    get navigable() {
        return this._navigable;
    }
    /**
     * Defines the number of records to be skipped by the pager
     * ([more details]({% slug paging_listview %})).
     */
    set skip(skip) {
        const parsed = parseInt(skip, 10);
        const defaultSkipValue = 0;
        this._skip = !isNaN(parsed) ? parsed : defaultSkipValue;
    }
    get skip() {
        return this._skip;
    }
    /**
     * Configures whether the ListView will render a pager
     * ([more details]({% slug paging_listview %})).
     * Providing a boolean value will render a pager with the default settings.
     */
    set pageable(pageable) {
        this._pageable = pageable;
        this.pagerSettings = pageable ? Object.assign({}, DEFAULT_PAGER_SETTINGS, pageable) : null;
    }
    get pageable() {
        return this._pageable;
    }
    /**
     * @hidden
     *
     * Gets the data items passed to the ListView.
     * If a ListViewDataResult is passed, the data value is used. If an array is passed - it's directly used.
     */
    get items() {
        if (!isPresent(this.data)) {
            return [];
        }
        return Array.isArray(this.data) ? this.data : this.data.data;
    }
    /**
     * @hidden
     *
     * Gets the total number of records passed to the ListView.
     * If a ListViewDataResult is passed, the total value is used. If an array is passed - its length is used.
     */
    get total() {
        if (!isPresent(this.data)) {
            return 0;
        }
        return Array.isArray(this.data) ? this.data.length : this.data.total;
    }
    /**
     * @hidden
     */
    get containerTabindex() {
        // workaround for FF, where a scrollable container is focusable even without a tabindex and creates an unwanted tab stop
        // https://bugzilla.mozilla.org/show_bug.cgi?id=616594
        return this.navigable ? -1 : null;
    }
    /**
     * Gets the current active item index
     * ([see example]({% slug keyboard_navigation_listview %}#toc-controlling-the-focus)).
     * Returns `null` when the keyboard navigation is disabled.
     */
    get activeIndex() {
        return this.navigationService.activeIndex;
    }
    ngOnChanges(changes) {
        if (isChanged('height', changes, false)) {
            this.renderer.setStyle(this.element.nativeElement, 'height', `${this.height}px`);
        }
    }
    ngOnDestroy() {
        if (isPresent(this.editServiceSubscription)) {
            this.editServiceSubscription.unsubscribe();
        }
    }
    /**
     * @hidden
     */
    templateContext(index) {
        return {
            "$implicit": this.items[index],
            "isLast": index === this.items.length - 1,
            "isFirst": index === 0,
            "dataItem": this.items[index],
            "index": index
        };
    }
    /**
     * @hidden
     */
    editTemplateContext(index) {
        const isNew = index === -1;
        const group = isNew ? this.editService.newItemGroup : this.editService.editGroup(index);
        return {
            "$implicit": group,
            "formGroup": group,
            "dataItem": isNew ? this.editService.newDataItem : this.items[index],
            "isNew": isNew,
            "index": index
        };
    }
    /**
     * Focuses the item at the specified index ([see example]({% slug keyboard_navigation_listview %}#toc-controlling-the-focus)):
     * - If no index is specified, the current active index will be focused.
     * - If the passed value is below `0`, the first item receives focus.
     * - If the passed value is above the last available index, the last item receives focus.
     *
     * > The index param is based on the logical structure of the ListView and does not correspond to the data item index -
     * > i.e. the index `0` corresponds to the first rendered list item. Paging is not taken into account.
     * > Also, for the focusing to work, the `navigable` prop must first be set to `true`.
     */
    focus(index) {
        const totalRenderedItems = this.listViewItems.length;
        this.navigationService.focusIndex(index, totalRenderedItems);
    }
    /**
     * Creates a new item editor ([see example]({% slug editing_template_forms_listview %}#toc-adding-records)).
     *
     * @param {FormGroup} group - The [`FormGroup`]({{ site.data.urls.angular['formgroupapi'] }}) that describes
     * the edit form. If called with a data item, it will build the `FormGroup` from the data item fields.
     */
    addItem(group) {
        const isFormGroup = group instanceof FormGroup;
        if (!isFormGroup) {
            const fields = Object.keys(group).reduce(createControl(group), {});
            group = new FormGroup(fields);
        }
        this.editService.addItem(group);
    }
    /**
     * Switches the specified item to edit mode ([see example]({% slug editing_template_forms_listview %}#toc-editing-records)).
     *
     * @param index - The item index that will be switched to edit mode.
     * @param group - The [`FormGroup`]({{ site.data.urls.angular['formgroupapi'] }})
     * that describes the edit form.
     */
    editItem(index, group) {
        this.editService.editItem(index, group);
    }
    /**
     * Closes the editor for a given item ([see example]({% slug editing_template_forms_grid %}#toc-cancelling-editing)).
     *
     * @param {number} index - The item index that will be switched out of the edit mode. If no index is provided, it is assumed
     * that the new item editor will be closed.
     */
    closeItem(index) {
        this.editService.close(index);
    }
    /**
     * @hidden
     */
    isEdited(index) {
        return this.editService.isEdited(index);
    }
    /**
     * @hidden
     */
    handlePageChange(event) {
        this.scrollToContainerTop();
        const firstIndex = 0;
        this.navigationService.setActiveIndex(firstIndex);
        this.pageChange.emit(event);
    }
    /**
     * @hidden
     */
    handleContentScroll() {
        if (!hasObservers(this.scrollBottom)) {
            return;
        }
        const THRESHOLD = 2;
        const { scrollHeight, scrollTop, clientHeight } = this.contentContainer.nativeElement;
        const atBottom = scrollHeight - clientHeight - scrollTop <= THRESHOLD;
        if (atBottom) {
            this.ngZone.run(() => {
                const event = { sender: this };
                this.scrollBottom.emit(event);
            });
        }
    }
    /**
     * @hidden
     */
    itemPosInSet(index) {
        // adds 1 as the aria-posinset is not zero-based and the counting starts from 1
        return this.skip + index + 1;
    }
    scrollToContainerTop() {
        const container = this.contentContainer.nativeElement;
        container.scrollTop = 0;
        container.scrollLeft = 0;
    }
    addNavigationListeners() {
        this.ngZone.runOutsideAngular(() => {
            const removeKeydownListener = this.renderer.listen(this.contentContainer.nativeElement, 'keydown', event => this.navigationService.handleKeyDown(event, this.listViewItems.length));
            const removeFocusInListener = this.renderer.listen(this.contentContainer.nativeElement, 'focusin', event => this.navigationService.handleFocusIn(event));
            const removeFocusOutListener = this.renderer.listen(this.contentContainer.nativeElement, 'focusout', event => this.navigationService.handleFocusOut(event));
            this.removeNavigationListeners = () => {
                removeKeydownListener();
                removeFocusInListener();
                removeFocusOutListener();
            };
        });
    }
    attachEditHandlers() {
        if (!isPresent(this.editService)) {
            return;
        }
        this.editServiceSubscription = this.editService
            .changes.subscribe(this.emitCRUDEvent.bind(this));
    }
    emitCRUDEvent(args) {
        const { action, itemIndex, formGroup } = args;
        let dataItem = this.items[itemIndex];
        if (action !== 'add' && formGroup) {
            dataItem = formGroup.value;
        }
        Object.assign(args, {
            dataItem: dataItem,
            sender: this
        });
        this[action].emit(args);
    }
};
__decorate([
    HostBinding('class.k-widget'),
    HostBinding('class.k-listview'),
    HostBinding('class.k-listview-bordered'),
    HostBinding('class.k-d-flex'),
    __metadata("design:type", Boolean)
], ListViewComponent.prototype, "className", void 0);
__decorate([
    ContentChild(ItemTemplateDirective, { static: false }),
    __metadata("design:type", ItemTemplateDirective)
], ListViewComponent.prototype, "itemTemplate", void 0);
__decorate([
    ContentChild(HeaderTemplateDirective, { static: false }),
    __metadata("design:type", HeaderTemplateDirective)
], ListViewComponent.prototype, "headerTemplate", void 0);
__decorate([
    ContentChild(FooterTemplateDirective, { static: false }),
    __metadata("design:type", FooterTemplateDirective)
], ListViewComponent.prototype, "footerTemplate", void 0);
__decorate([
    ContentChild(LoaderTemplateDirective, { static: false }),
    __metadata("design:type", LoaderTemplateDirective)
], ListViewComponent.prototype, "loaderTemplate", void 0);
__decorate([
    ViewChild('contentContainer', { static: true }),
    __metadata("design:type", ElementRef)
], ListViewComponent.prototype, "contentContainer", void 0);
__decorate([
    ContentChild(EditTemplateDirective, { static: false }),
    __metadata("design:type", EditTemplateDirective)
], ListViewComponent.prototype, "editTemplate", void 0);
__decorate([
    ViewChildren(ListViewNavigableItemDirective),
    __metadata("design:type", QueryList)
], ListViewComponent.prototype, "listViewItems", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ListViewComponent.prototype, "data", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ListViewComponent.prototype, "loading", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ListViewComponent.prototype, "containerStyle", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ListViewComponent.prototype, "itemStyle", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ListViewComponent.prototype, "containerClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ListViewComponent.prototype, "itemClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ListViewComponent.prototype, "containerLabel", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ListViewComponent.prototype, "containerRole", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ListViewComponent.prototype, "listItemRole", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], ListViewComponent.prototype, "navigable", null);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ListViewComponent.prototype, "pageSize", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], ListViewComponent.prototype, "skip", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], ListViewComponent.prototype, "pageable", null);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ListViewComponent.prototype, "height", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ListViewComponent.prototype, "scrollBottom", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ListViewComponent.prototype, "pageChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ListViewComponent.prototype, "pageSizeChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ListViewComponent.prototype, "edit", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ListViewComponent.prototype, "cancel", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ListViewComponent.prototype, "save", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ListViewComponent.prototype, "remove", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ListViewComponent.prototype, "add", void 0);
ListViewComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        exportAs: 'kendoListView',
        selector: 'kendo-listview',
        providers: [
            EditService,
            NavigationService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.listview'
            }
        ],
        template: `
        <!-- top pager -->
        <ng-template
            *ngIf="pagerSettings?.position !== 'bottom'"
            [ngTemplateOutlet]="pagerTemplate"
            [ngTemplateOutletContext]="{ pagerClass: 'k-listview-pager k-listview-pager-top' }"
        >
        </ng-template>

        <!-- header -->
        <div
            *ngIf="headerTemplate"
            class="k-listview-header"
        >
            <ng-template
                [ngTemplateOutlet]="headerTemplate?.templateRef"
            >
            </ng-template>
        </div>

        <!-- content -->
        <div
            #contentContainer
            [attr.tabindex]="containerTabindex"
            class="k-listview-content"
            [ngClass]="containerClass"
            [ngStyle]="containerStyle"
            [kendoEventsOutsideAngular]="{
                scroll: handleContentScroll
            }"
            [scope]="this"
            [attr.role]="containerRole"
            [attr.aria-label]="containerLabel"
        >
            <!-- new item edit template -->
            <div
                *ngIf="editService.hasNewItem"
                class="k-listview-item"
                [attr.role]="listItemRole"
                kendoListViewNavigableItem
                [index]="-1"
                [attr.data-kendo-listview-item-index]="-1"
                [ngClass]="itemClass"
                [ngStyle]="itemStyle"
            >
                <ng-template
                    *ngIf="editTemplate"
                    [ngTemplateOutlet]="editTemplate?.templateRef"
                    [ngTemplateOutletContext]="editTemplateContext(-1)"
                >
                </ng-template>
            </div>

            <!-- items -->
            <div
                *ngFor="let dataItem of items; let index = index; let first = first; let last = last;"
                class="k-listview-item"
                [attr.role]="listItemRole"
                [attr.aria-posinset]="itemPosInSet(index)"
                [attr.aria-setsize]="total"
                kendoListViewNavigableItem
                [index]="index"
                [attr.data-kendo-listview-item-index]="index"
                [ngClass]="itemClass"
                [ngStyle]="itemStyle"
            >
                <ng-template
                    [ngTemplateOutlet]="isEdited(index) ? editTemplate?.templateRef : itemTemplate?.templateRef"
                    [ngTemplateOutletContext]="isEdited(index) ? editTemplateContext(index) : templateContext(index)"
                >
                </ng-template>
            </div>

            <!-- loading indicator -->
            <div
                *ngIf="loading && !loaderTemplate"
                class="k-loading-mask"
            >
                <!-- TODO: the k-loading-text is hidden with css but read by readers - review when implementing accessibility + possible localization case -->
                <span class="k-loading-text">Loading</span>
                <div class="k-loading-image"></div>
                <div class="k-loading-color"></div>
            </div>
            <ng-template
                *ngIf="loading && loaderTemplate"
                [ngTemplateOutlet]="loaderTemplate.templateRef"
            >
            </ng-template>
        </div>

        <!-- footer -->
        <div
            *ngIf="footerTemplate"
            class="k-listview-footer"
        >
            <ng-template
                [ngTemplateOutlet]="footerTemplate?.templateRef"
            >
            </ng-template>
        </div>

        <!-- bottom pager -->
        <ng-template
            *ngIf="pagerSettings?.position !== 'top'"
            [ngTemplateOutlet]="pagerTemplate"
            [ngTemplateOutletContext]="{ pagerClass: 'k-listview-pager' }"
        >
        </ng-template>

        <!-- pager template -->
        <ng-template #pagerTemplate let-pagerClass="pagerClass">
            <kendo-datapager
                *ngIf="pageable"
                [class]="pagerClass"
                [total]="total"
                [pageSize]="pageSize"
                [skip]="skip"
                [buttonCount]="pagerSettings.buttonCount"
                [info]="pagerSettings.info"
                [previousNext]="pagerSettings.previousNext"
                [type]="pagerSettings.type"
                [pageSizeValues]="pagerSettings.pageSizeValues"
                (pageChange)="handlePageChange($event)"
                (pageSizeChange)="pageSizeChange.emit($event)"
            >
            </kendo-datapager>
        </ng-template>
    `
    }),
    __metadata("design:paramtypes", [NgZone,
        ElementRef,
        Renderer2,
        EditService,
        NavigationService])
], ListViewComponent);

/**
 * A directive that encapsulates the in-memory handling of paging
 * ([see example]({% slug paging_listview %}#toc-binding-directive)).
 */
let DataBindingDirective = class DataBindingDirective {
    constructor(listView) {
        this.listView = listView;
        this.subscriptions = new Subscription();
    }
    /**
     * The array of data which will be used to populate the ListView.
     */
    set data(data) {
        this._data = data || [];
        this.updateListViewData();
    }
    get data() {
        return this._data;
    }
    ngOnInit() {
        this.subscriptions.add(this.listView.pageChange.subscribe(this.handlePageChange.bind(this)));
        this.subscriptions.add(this.listView.pageSizeChange.subscribe(this.handlePageSizeChange.bind(this)));
        this.updateListViewData();
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
    handlePageChange(event) {
        this.listView.skip = event.skip;
        this.listView.pageSize = event.take;
        this.updateListViewData();
    }
    handlePageSizeChange(event) {
        this.listView.pageSize = Number(event.newPageSize);
    }
    updateListViewData() {
        const from = this.listView.skip || 0;
        const to = from + (this.listView.pageSize || this.data.length);
        this.listView.data = {
            data: this.data.slice(from, to),
            total: this.data.length
        };
    }
};
__decorate([
    Input('kendoListViewBinding'),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], DataBindingDirective.prototype, "data", null);
DataBindingDirective = __decorate([
    Directive({
        selector: '[kendoListViewBinding]'
    }),
    __metadata("design:paramtypes", [ListViewComponent])
], DataBindingDirective);

/**
 * The arguments of the [`pageSizeChange`]({% slug api_listview_listviewcomponent %}#toc-pagesizechange) event of the ListView
 * ([more details]({% slug paging_listview %}).
 */
class PageSizeChangeEvent$1 extends PageSizeChangeEvent {
}

/**
 * @hidden
 */
let TemplateContextDirective = class TemplateContextDirective {
    constructor(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
    }
    set templateContext(context) {
        if (this.insertedViewRef) {
            this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.insertedViewRef));
            this.insertedViewRef = undefined;
        }
        if (context.templateRef) {
            this.insertedViewRef = this.viewContainerRef.createEmbeddedView(context.templateRef, context);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], TemplateContextDirective.prototype, "templateContext", null);
TemplateContextDirective = __decorate([
    Directive({
        selector: '[templateContext]' // tslint:disable-line
    }),
    __metadata("design:paramtypes", [ViewContainerRef])
], TemplateContextDirective);

/**
 * Represents the `edit` command of the ListView. You can apply this directive to any `button`
 * element inside a [`EditTemplateDirective`]({% slug api_listview_edittemplatedirective %}) template.
 * When an associated button with the directive is clicked, the
 * [`edit`]({% slug api_listview_listviewcomponent %}#toc-edit) event
 * is triggered ([see example]({% slug editing_listview %})).
 *
 * @example
 * ```html-no-run
 * <kendo-listview>
 *   <ng-template kendoListViewEditTemplate>
 *     <button kendoListViewEditCommand class="k-primary">Edit</button>
 *   </ng-template>
 * </kendo-listview>
 * ```
 *
 */
let EditCommandDirective = class EditCommandDirective extends Button {
    constructor(editService, element, renderer, localization, ngZone) {
        super(element, renderer, null, localization, ngZone);
        this.editService = editService;
        this.elementRef = element;
    }
    /**
     * @hidden
     */
    clickHandler(e) {
        e.preventDefault();
        const index = getClosestListItemIndex(this.elementRef.nativeElement);
        this.editService.beginEdit(index);
    }
};
__decorate([
    HostListener('click', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], EditCommandDirective.prototype, "clickHandler", null);
EditCommandDirective = __decorate([
    Directive({
        selector: '[kendoListViewEditCommand]'
    }),
    __metadata("design:paramtypes", [EditService,
        ElementRef,
        Renderer2,
        LocalizationService,
        NgZone])
], EditCommandDirective);

/**
 * Represents the `cancel` command of the ListView. You can apply this directive to any `button`
 * element inside a [`EditTemplateDirective`]({% slug api_listview_edittemplatedirective %}) template.
 * When an associated button with the directive is clicked, the
 * [`cancel`]({% slug api_listview_listviewcomponent %}#toc-cancel) event
 * is triggered ([see example]({% slug editing_listview %})).
 *
 * @example
 * ```html-no-run
 * <kendo-listview>
 *   <ng-template kendoListViewEditTemplate>
 *     <button kendoListViewCancelCommand>Cancel changes</button>
 *   </ng-template>
 * </kendo-listview>
 * ```
 *
 * You can control the content of the button based on the state of the item.
 *
 * @example
 * ```html-no-run
 * <kendo-listview>
 *   <ng-template kendoListViewEditTemplate let-isNew="isNew">
 *     <button kendoListViewCancelCommand>{{isNew ? 'Discard' : 'Cancel changes'}}</button>
 *   </ng-template>
 * </kendo-listview>
 * ```
 */
let CancelCommandDirective = class CancelCommandDirective extends Button {
    constructor(editService, element, renderer, localization, ngZone) {
        super(element, renderer, null, localization, ngZone);
        this.editService = editService;
        this.elementRef = element;
    }
    /**
     * @hidden
     */
    clickHandler(e) {
        e.preventDefault();
        const index = getClosestListItemIndex(this.elementRef.nativeElement);
        if (this.editService.isEdited(index)) {
            this.editService.endEdit(index);
        }
    }
};
__decorate([
    HostListener('click', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], CancelCommandDirective.prototype, "clickHandler", null);
CancelCommandDirective = __decorate([
    Directive({
        selector: '[kendoListViewCancelCommand]'
    }),
    __metadata("design:paramtypes", [EditService,
        ElementRef,
        Renderer2,
        LocalizationService,
        NgZone])
], CancelCommandDirective);

/**
 * Represents the `save` command of the ListView. You can apply this directive to any `button`
 * element inside a [`EditTemplateDirective`]({% slug api_listview_edittemplatedirective %}) template.
 * When an associated button with the directive is clicked, the
 * [`save`]({% slug api_listview_listviewcomponent %}#toc-save) event
 * is triggered ([see example]({% slug editing_listview %})).
 *
 * @example
 * ```html-no-run
 * <kendo-listview>
 *   <ng-template kendoListViewEditTemplate>
 *     <button kendoListViewSaveCommand>Save changes</button>
 *   </ng-template>
 * </kendo-listview>
 * ```
 *
 * You can control the content of the button based on the state of the item.
 *
 * @example
 * ```html-no-run
 * <kendo-listview>
 *   <ng-template kendoListViewEditTemplate let-isNew="isNew">
 *     <button kendoListViewSaveCommand>{{isNew ? 'Add' : 'Update'}}</button>
 *   </ng-template>
 * </kendo-listview>
 * ```
 */
let SaveCommandDirective = class SaveCommandDirective extends Button {
    constructor(editService, element, renderer, localization, ngZone) {
        super(element, renderer, null, localization, ngZone);
        this.editService = editService;
        this.elementRef = element;
    }
    /**
     * @hidden
     */
    clickHandler(e) {
        e.preventDefault();
        const index = getClosestListItemIndex(this.elementRef.nativeElement);
        if (this.editService.isEdited(index)) {
            this.editService.save(index);
        }
    }
};
__decorate([
    HostListener('click', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SaveCommandDirective.prototype, "clickHandler", null);
SaveCommandDirective = __decorate([
    Directive({
        selector: '[kendoListViewSaveCommand]'
    }),
    __metadata("design:paramtypes", [EditService,
        ElementRef,
        Renderer2,
        LocalizationService,
        NgZone])
], SaveCommandDirective);

/**
 * Represents the `remove` command of the ListView. You can apply this directive to any `button` element
 * inside a [`EditTemplateDirective`]({% slug api_listview_edittemplatedirective %}) template.
 * When an associated button with the directive is clicked, the
 * [`remove` event]({% slug api_listview_listviewcomponent %}#toc-remove)
 * is triggered ([see example]({% slug editing_listview %})).
 *
 * @example
 * ```html-no-run
 * <kendo-listview>
 *   <ng-template kendoListViewEditTemplate>
 *     <button kendoListViewRemoveCommand>Remove item</button>
 *   </ng-template>
 * </kendo-listview>
 * ```
 */
let RemoveCommandDirective = class RemoveCommandDirective extends Button {
    constructor(editService, element, renderer, localization, ngZone) {
        super(element, renderer, null, localization, ngZone);
        this.editService = editService;
        this.elementRef = element;
    }
    /**
     * @hidden
     */
    clickHandler(e) {
        e.preventDefault();
        const index = getClosestListItemIndex(this.elementRef.nativeElement);
        this.editService.remove(index);
    }
};
__decorate([
    HostListener('click', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], RemoveCommandDirective.prototype, "clickHandler", null);
RemoveCommandDirective = __decorate([
    Directive({
        selector: '[kendoListViewRemoveCommand]'
    }),
    __metadata("design:paramtypes", [EditService,
        ElementRef,
        Renderer2,
        LocalizationService,
        NgZone])
], RemoveCommandDirective);

/**
 * Represents the command for adding a new item to the ListView. You can apply this directive to any
 * `button` element inside a [`HeaderTemplate`]({% slug api_listview_headertemplatedirective %}).
 * When an associated button with the directive is clicked, the
 * [`add`]({% slug api_listview_listviewcomponent %}#toc-add) event is triggered
 * ([see example]({% slug editing_listview %})).
 *
 * @example
 * ```html-no-run
 * <kendo-listview>
 *    <ng-template kendoListViewHeaderTemplate>
 *       <button kendoListViewAddCommand>Add new</button>
 *    </ng-template>
 * </kendo-listview>
 * ```
 */
let AddCommandDirective = class AddCommandDirective extends Button {
    constructor(editService, element, renderer, localization, ngZone) {
        super(element, renderer, null, localization, ngZone);
        this.editService = editService;
    }
    /**
     * @hidden
     */
    clickHandler(e) {
        e.preventDefault();
        this.editService.beginAdd();
    }
};
__decorate([
    HostListener('click', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AddCommandDirective.prototype, "clickHandler", null);
AddCommandDirective = __decorate([
    Directive({
        selector: '[kendoListViewAddCommand]'
    }),
    __metadata("design:paramtypes", [EditService,
        ElementRef,
        Renderer2,
        LocalizationService,
        NgZone])
], AddCommandDirective);

const TEMPLATE_DIRECTIVES = [
    ItemTemplateDirective,
    HeaderTemplateDirective,
    FooterTemplateDirective,
    LoaderTemplateDirective
];
const BINDING_DIRECTIVES = [
    DataBindingDirective
];
const EDITING_DIRECTIVES = [
    EditTemplateDirective,
    EditCommandDirective,
    CancelCommandDirective,
    SaveCommandDirective,
    RemoveCommandDirective,
    AddCommandDirective
];
/**
 * The exported package module.
 *
 * The package exports:
 * - [`ListViewComponent`]({% slug api_listview_listviewcomponent %})&mdash;The ListView component class.
 * - [`ItemTemplateDirective`]({% slug api_listview_itemtemplatedirective %})&mdash;The item template directive.
 * - [`HeaderTemplateDirective`]({% slug api_listview_headertemplatedirective %})&mdash;The header template directive.
 * - [`FooterTemplateDirective`]({% slug api_listview_footertemplatedirective %})&mdash;The footer template directive.
 * - [`LoaderTemplateDirective`]({% slug api_listview_loadertemplatedirective %})&mdash;The loeader template directive.
 * - [`DataBindingDirective`]({% slug api_listview_databindingdirective %})&mdash;The data binding directive.
 * - [`EditTemplateDirective`]({% slug api_listview_editingdirective %}&mdash;The editing template directive.
 */
let ListViewModule = class ListViewModule {
};
ListViewModule = __decorate([
    NgModule({
        declarations: [
            TEMPLATE_DIRECTIVES,
            BINDING_DIRECTIVES,
            EDITING_DIRECTIVES,
            ListViewComponent,
            TemplateContextDirective,
            ListViewNavigableItemDirective
        ],
        exports: [
            TEMPLATE_DIRECTIVES,
            BINDING_DIRECTIVES,
            EDITING_DIRECTIVES,
            ListViewComponent,
            CommonModule,
            EventsModule
        ],
        imports: [
            CommonModule,
            PagerModule,
            EventsModule
        ]
    })
], ListViewModule);

/**
 * Generated bundle index. Do not edit.
 */

export { AddCommandDirective, CancelCommandDirective, EditCommandDirective, RemoveCommandDirective, SaveCommandDirective, EditService, ListViewNavigableItemDirective, NavigationService, TemplateContextDirective, ListViewComponent, ItemTemplateDirective, HeaderTemplateDirective, FooterTemplateDirective, LoaderTemplateDirective, DataBindingDirective, EditTemplateDirective, PageSizeChangeEvent$1 as PageSizeChangeEvent, ListViewModule };
